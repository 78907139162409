@use "styles/atoms/typography";
@use "/styles/vars";

.LanguageSelector {
  position: relative;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: #fff;
  font-family: typography.$heading-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  background-image: url(vars.$ASSET_BASE_URL +'/static/svgs/dropdown-caret.svg');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 10px 6px;
  padding-right: 15px;
}
@use "/styles/atoms/colors";
@use "/styles/atoms/animations";

#chosenPrice {
  border-color: #EA580C !important;
  color: #fff !important;
}

body {
  &, html {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  button {
    cursor: pointer;
  }

  #code-input {
    input {
      font-family: inherit!important;
    }
  }

  .reskin {

      a {
        color: inherit;
        text-decoration: none;
      }
      
      * {
        box-sizing: border-box;
      }
      
      .rheostat {
        position: relative;
      }
      
      .pac-container {
        font-family: "Inter"!important;
      }
      
      .DefaultProgressBar_progressBar {
        background: colors.$rea-orange;
        position: absolute;
        height: 5px;
        top: 0px;
        border-radius: 5px;
        transition: height animations.$timing
        animations.$easing;
      }
      
      [data-reach-combobox-popover] {
        border: none!important;
      }
      
      .DefaultHandle_handle {
        width: 20px !important;
        height: 20px !important;
        border-width: 1px !important;
        border-style: solid !important;
        border-color: colors.$rea-orange !important;
        background-color: colors.$rea-orange !important;
        border-radius: 50% !important;
        outline: none !important;
        z-index: 2 !important;
        box-shadow: none !important;
        margin-left: -12px !important;
        top: -7px !important;
        cursor: pointer;
      }
      
      [data-suggested-value] {
        font-weight: 400!important;
      }
      
      [data-user-value] {
        font-weight: bold;
      }
  }

  .orginal {
    a {
      color: inherit;
      text-decoration: none;
    }
    
    * {
      box-sizing: border-box;
    }
    
    .rheostat {
      position: relative;
    }
    
    .pac-container {
      font-family: "Inter"!important;
    }
    
    .DefaultProgressBar_progressBar {
      background: #EA580C;
      position: absolute;
      height: 13px;
      top: 0px;
    }
    
    [data-reach-combobox-popover] {
      border: none!important;
    }
    
    .DefaultHandle_handle {
      width: 24px !important;
      height: 24px !important;
      border-width: 1px !important;
      border-style: solid !important;
      border-color: rgb(216, 216, 216) !important;
      background-color: rgb(245, 246, 248) !important;
      border-radius: 20% !important;
      outline: none !important;
      z-index: 2 !important;
      box-shadow: rgb(219 219 219) 0px 2px 2px !important;
      margin-left: -12px !important;
      top: -5px !important;
      cursor: pointer;
    }
    
    .DefaultHandle_handle:before {
      content: "" !important;
      display: block !important;
      position: absolute !important;
      background-color: rgb(218, 223, 232) !important;
      top: 7px !important;
      height: 10px !important;
      width: 1px !important;
      left: 10px !important;
    }
    
    .DefaultHandle_handle:after {
      content: "" !important;
      display: block !important;
      position: absolute !important;
      background-color: rgb(218, 223, 232) !important;
      top: 7px !important;
      height: 10px !important;
      width: 1px !important;
      left: 13px !important;
    }
    
    [data-suggested-value] {
      font-weight: 400!important;
    }
    
    [data-user-value] {
      font-weight: bold;
    }
  }
}
@use "colors";
@use "/styles/core";

// You are own your own for loading the font into your app.
$heading-font: "Inter", "Ubuntu", sans-serif;
$copy-font: $heading-font;

%hero-header,
%h1,
%h2,
%h3,
%h4 {
  font-family: $heading-font;
  color: colors.$text-black;
  font-style: normal;
}

%hero-header {
  @include core.media("min", "1025px") {
    font-size: 60px;
    line-height: 1.06;
  }
  font-weight: 700;
  font-size: 36px;
  line-height: 1.16;
}

%h1 {
  @include core.media("min", "1025px") {
    font-size: 42px;
    line-height: 1.28;
  }
  font-weight: 600;
  font-size: 28px;
  line-height: 1.21;
}

%h2 {
  @include core.media("min", "1025px") {
    font-size: 32px;
    line-height: 1.25;
  }
  font-weight: 600;
  font-size: 24px;
  line-height: 1.21;
}

%h3 {
  @include core.media("min", "1025px") {
    font-size: 24px;
    line-height: 1.25;
  }
  font-weight: 600;
  font-size: 22px;
  line-height: 1.25;
}

%h4 {
  @include core.media("min", "1025px") {
    font-size: 20px;
    line-height: 1.2;
  }
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33;
}

%body,
%body-large,
%body-small,
%subheader,
%subheader-small,
%label,
%button,
%button-large,
%button-small {
  font-family: $copy-font;
  color: colors.$text-black;
  font-style: normal;

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }
}

%body {
  @include core.media("min", "1025px") {
    font-size: 16px;
    line-height: 1.25;
  }
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;

  &-large {
    @include core.media("min", "1025px") {
      font-size: 18px;
      line-height: 1.55;
    }
    font-size: 16px;
    line-height: 1.38;
  }

  &-small {
    @include core.media("min", "1025px") {
      font-size: 14px;
      line-height: 1.21;
    }
    font-size: 12px;
    line-height: 1.21;
  }
}

%subheader {
  @include core.media("min", "1025px") {
    font-size: 16px;
    line-height: 1.25;
  }
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.13;
  letter-spacing: 0.03em;

  &-small {
    @include core.media("min", "1025px") {
      font-size: 12px;
      line-height: 1.21;
    }
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.01em;
  }
}

%label {
  font-weight: 400;
  font-size: 10px;
  line-height: 1.2;
}

%button {
  @include core.media("min", "1025px") {
    font-size: 16px;
    line-height: 1.19;
  }
  font-weight: 600;
  font-size: 14px;
  line-height: 2;

  &-large {
    @include core.media("min", "1025px") {
      font-size: 18px;
      line-height: 1.21;
    }
    font-size: 16px;
    line-height: 1.21;
  }

  &-small {
    @include core.media("min", "1025px") {
      font-size: 14px;
      line-height: 1.21;
    }
    font-size: 14px;
    line-height: 2;
  }
}

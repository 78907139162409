@use "/styles/atoms/typography";
@use "/styles/core";
@use "/styles/vars";

.Header {
  &__container {
    @extend %width-restrict;
    @extend %body;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding-top: 22px;
    padding-bottom: 22px;
    color: #fff;
    column-gap: 20px;
    max-width: 1280px;

    @media only screen and (max-width: 767px) {
      padding-right: 8px;
      padding-left: 8px;
      padding-top: 12px;
      padding-bottom: 16px;
    }
  }

  &__container_borderless {
    color: #292b29;
  }

  a {
    color: inherit;
    text-decoration: inherit;

    &[href^="tel:"] {
      position: relative;
      &::before {
        @include core.media("min", "768px") {
          content: "";
          position: absolute;
          display: inline-block;
          height: 23px;
          width: 23px;
          background-image: url(vars.$ASSET_BASE_URL + "/static/svgs/phone-icon.svg");
          background-position: center;
          left: (23px + 5px) * -1;
        }
      }
    }
  }

  &__logo {
    @include core.media("max", "767px") {
      max-width: 210px;
      width: 210px;
      height: 35.5px;
    }
    @include core.media("min", "768px") {
      min-width: 234px;
      max-width: 235px;
      width: 235px;
      height: 40px;
    }
    position: relative;
    width: 100%;
  }

  &__borderless_logo {
    @include core.media("max", "767px") {
      width: 182.98px;
      height: 35.5px;
    }
    @include core.media("min", "768px") {
      width: 206.185px;
      height: 40px;
    }
    position: relative;
    width: 100%;
  }

  &__brand {
    @include core.media("max", "396px") {
      text-align: center;
    }
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__phone_link {
    @include core.media("max", "767px") {
      font-size: 12px;
    }
  }

  &__phone {
    @include core.media("min", "396px") {
      justify-self: end;
      text-align: right;
    }

    @include core.media("max", "767px") {
      font-size: 12px;
    }

    margin-left: auto;
    padding-top: 0;
    padding-bottom: 0;
    justify-self: center;
    text-align: right;
  }

  &__phone_borderless {
    display: flex;
    flex-direction: row;
    white-space: pre;
  }

  &__flag {
    @include core.media("min", "768px") {
      display: inline;
    }
    display: none;
    padding-left: 10px;
  }
}

.mobile {
  @include core.media("min", "768px") {
    display: none;
  }
}

.desktop {
  @include core.media("max", "767px") {
    display: none;
  }
}

.app {
  font-family: "Inter", "Ubuntu", sans-serif;
  background-repeat: no-repeat;
  background-size: cover !important;
  min-height: 100vh;

  background-size: cover;
  background-position: top center;
  /* padding-top: 94px; */
  /* padding-bottom: 184px; */
  /* min-height: calc(100vh + 40px); */
}

.appInner {
  margin: 0 auto;
  min-height: 100vh;
}

@media only screen and (min-width: 768px) {
  .appInner {
    width: 750px;
  }
}

@media only screen and (min-width: 992px) {
  .appInner {
    width: 970px;
  }
}

@media only screen and (min-width: 1200px) {
  .appInner {
    width: 1170px;
  }
}

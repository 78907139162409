@use "/styles/atoms/colors";
@use "/styles/atoms/typography";
@use "/styles/core";

.Footer {
  background-color: colors.$rea-blue;

  &__container {
    @extend %width-restrict;
    padding-top: 24px;
    padding-bottom: 20px;

    a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  &__top {
    @include core.media('min', '938px') {
      flex-direction: row;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 17px;
    flex-direction: column;
  }

  &__bottom {
    @include core.media('min', '824px') {
      align-items: flex-end;
    }
    @include core.media('min', '444px') {
      flex-direction: row;
      align-items: flex-start;

    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 17px;
  }

  &__nav {
    text-align: center;
    padding-left: 0;
    li {
      @extend %body-small;
      @include core.media('min', '543px') {
        padding-bottom: 0;
      }
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      color: #fff;

      &:first-of-type {
        @include core.media('min', '543px') {
          padding-left: 0;
        }
      }
    }
  }

  &__icons {
    @include core.media('min', '938px') {
      margin-top: 0;
    }
    @include core.media('min', '543px') {
      margin-top: 24px;
    }
    @include core.media('min', '340px') {
      flex-direction: row;
    }
    display: flex;
    flex-direction: column;
    margin-top: 4px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    & + .Footer__icon {
      @include core.media('min', '340px') {
        padding-left: 11px;
        padding-top: 0;
      }
      padding-top: 11px;
    }
  }

  &__copyright {
    @extend %body-small;
    @include core.media('min', '444px') {
      padding-right: 20px;
      padding-bottom: 0;

    }
    color: rgba(255, 255, 255, 0.6);
    padding-bottom: 20px;

  }
}

.bbbContainer {
  @include core.media('min', '340px') {
    max-width: none;
  }
  width: 86px;
  height: 34px;
  overflow: hidden;
  display: inline-block;
  max-width: 51px;
  margin: 0 auto;

  img {
    width: auto;
    height: 100%;
    max-height: 34px;
  }
}
$rea-orange: #EA580C;
$rea-blue: #1E293B;
$rea-beige: #F3F3EF;

$rea-orange--hover: rgba(255, 105, 27, 1);
$rea-orange--active: rgba(224, 79, 3, 1);

$rea-blue--hover: rgba(30, 47, 73, 1);
$rea-blue--active: rgba(21, 31, 47, 1);

$text-black: #272727;
$light-beige: #FCFCFB;
$inputs-gray: #F8F8F8;

$disabled: rgba(210, 212, 216, 1);

$blue: #006BB8;
$green: #047857;
$yellow: #F59E0B;

$inputs-success: rgba(15, 119, 74, 1);
$inputs-error: rgba(163, 38, 0, 1);
